import { Link } from 'react-router-dom';
import React from 'react'; 
import MissionImage from '../../Assets/Main Images/missionimp.jpg'; // Update the path to your actual image

const OurMission = () => {
  return (
    <div className="bg-white text-gray-800">

      {/* Hero Section */}
      <section
        className="relative bg-cover bg-center h-[150vh] flex items-center justify-center text-center text-white"
        style={{
          backgroundImage: `url(${MissionImage})`, // Using the imported image here
          backgroundColor: '#35AC9F',
          backgroundBlendMode: 'multiply',
        }}
      >
        <div className="px-6 md:px-12 lg:px-24 text-center">
          <h1 className="text-5xl font-bold mb-4">Our Mission</h1>
          <p className="text-lg md:text-xl">
            Committed to delivering excellence through safety, quality, and innovation in every project we undertake.
          </p>
        </div>
      </section>

      {/* Mission Statement Section */}
      <section className="px-8 py-16 md:px-16 lg:px-32 text-center">
        <h2 className="text-4xl font-bold text-[#A97C50] mb-6">Our Mission Statement</h2>
        <p className="text-lg leading-relaxed mb-12">
          At Pacific Coast Solutions Contracting, we strive to exceed expectations by implementing cutting-edge technology and sustainable practices. 
          Our mission is to ensure the safety of our workforce while delivering quality results that meet our clients' needs and contribute positively to the community.
        </p>
      </section>

      {/* Mission Highlights Section */}
      <section className="px-8 py-16 md:px-16 lg:px-32 bg-gray-100 text-center">
        <h2 className="text-4xl font-bold text-[#35AC9F] mb-6">Key Mission Highlights</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {[
            {
              title: 'Safety First',
              description: 'Prioritizing the well-being of our team and clients.',
              bgColor: '#35AC9F',
              textColor: '#fff',
            },
            {
              title: 'Quality Commitment',
              description: 'Delivering outstanding results through rigorous quality assurance.',
              bgColor: '#A97C50',
              textColor: '#fff',
            },
            {
              title: 'Innovative Practices',
              description: 'Embracing technology to improve project efficiency.',
              bgColor: '#35AC9F',
              textColor: '#fff',
            },
            {
              title: 'Client Satisfaction',
              description: 'Ensuring client needs are met with utmost dedication.',
              bgColor: '#A97C50',
              textColor: '#fff',
            },
            {
              title: 'Community Engagement',
              description: 'Contributing positively to the communities we serve.',
              bgColor: '#35AC9F',
              textColor: '#fff',
            },
            {
              title: 'Sustainability',
              description: 'Implementing sustainable practices in all our projects.',
              bgColor: '#A97C50',
              textColor: '#fff',
            },
          ].map((item, index) => (
            <div key={index} className="relative w-full h-64 perspective transition-transform duration-300 transform hover:scale-105">
              <div className="absolute inset-0 rounded-lg flex items-center justify-center p-6" style={{ backgroundColor: item.bgColor }}>
                <h3 className="text-2xl font-semibold" style={{ color: item.textColor }}>
                  {item.title}
                </h3>
              </div>
              <div className="absolute inset-0 bg-gray-100 rounded-lg flex items-center justify-center p-6 transform rotate-y-180 transition-transform duration-300 hover:rotate-y-0">
                <p className="text-lg text-center text-gray-800">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Call to Action */}
      <section className="px-8 py-16 md:px-16 lg:px-32 text-center">
        <button className="px-8 py-4 text-lg font-semibold text-white bg-[#35AC9F] rounded-lg shadow-md border-2 border-[#A97C50] hover:bg-[#A97C50] transition duration-300">
          Learn More
        </button>
      </section>

    </div>
  );
};

export default OurMission;
