import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Assets/Logo/Logo_or.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredMenu, setHoveredMenu] = useState('');

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseEnter = (menu) => {
    setHoveredMenu(menu);
  };

  const handleMouseLeave = () => {
    setHoveredMenu('');
  };

  return (
    <nav className="fixed z-20 w-full bg-transparent shadow-md">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 md:h-20">
          {/* Logo */}
          <div className="flex items-center flex-shrink-0">
            <Link to="/">
              <img className="w-16 h-auto md:w-24 lg:w-28 mt-9" src={Logo} alt="Logo" />
            </Link>
          </div>

          {/* Hamburger Icon */}
          <button
            onClick={toggleMenu}
            className="text-[#A97C50] text-2xl focus:outline-none md:hidden"
          >
            {isOpen ? '✖' : '☰'}
          </button>

          {/* Desktop Navigation Links */}
          <div className={`hidden md:flex items-center space-x-6`}>
            <Link to="/" className="px-5 py-2 text-[#A97C50] hover:text-teal-400">Home</Link>

            {/* Who Are We */}
            <div
              className="relative group"
              onMouseEnter={() => handleMouseEnter('whoAreWe')}
              onMouseLeave={handleMouseLeave}
            >
              <button className="px-5 py-2 text-[#A97C50] hover:text-teal-400">Who Are We</button>
              {hoveredMenu === 'whoAreWe' && (
                <div className="absolute left-0 w-48 bg-transparent shadow-lg">
                  <Link to="/about-us" className="block px-4 py-2 text-[#A97C50] hover:text-teal-400">About Us</Link>
                  <Link to="/our-mission" className="block px-4 py-2 text-[#A97C50] hover:text-teal-400">Our Mission</Link>
                </div>
              )}
            </div>

            {/* What We Do */}
            <div
              className="relative group"
              onMouseEnter={() => handleMouseEnter('whatWeDo')}
              onMouseLeave={handleMouseLeave}
            >
              <button className="px-5 py-2 text-[#A97C50] hover:text-teal-400">What We Do</button>
              {hoveredMenu === 'whatWeDo' && (
                <div className="absolute left-0 w-48 bg-transparent shadow-lg">
                  <Link to="/services" className="block px-4 py-2 text-[#A97C50] hover:text-teal-400">Services</Link>
                </div>
              )}
            </div>

            {/* Projects */}
            <div
              className="relative group"
              onMouseEnter={() => handleMouseEnter('projects')}
              onMouseLeave={handleMouseLeave}
            >
              <button className="px-5 py-2 text-[#A97C50] hover:text-teal-400">Projects</button>
              {hoveredMenu === 'projects' && (
                <div className="absolute left-0 w-48 bg-transparent shadow-lg">
                  <Link to="/projects" className="block px-4 py-2 text-[#A97C50] hover:text-teal-400">Projects</Link>
                </div>
              )}
            </div>

            <Link to="/contact" className="px-5 py-2 text-[#A97C50] hover:text-teal-400">Contact</Link>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="bg-transparent md:hidden">
          <div className="px-4 py-4 space-y-2">
            <Link to="/" className="block text-[#A97C50] hover:text-teal-400">Home</Link>
            <div>
              <button
                onClick={() => handleMouseEnter('whoAreWe')}
                className="block w-full text-left text-[#A97C50] hover:text-teal-400"
              >
                Who Are We
              </button>
              {hoveredMenu === 'whoAreWe' && (
                <div className="pl-4">
                  <Link to="/about-us" className="block py-1 text-[#A97C50] hover:text-teal-400">About Us</Link>
                  <Link to="/our-mission" className="block py-1 text-[#A97C50] hover:text-teal-400">Our Mission</Link>
                </div>
              )}
            </div>
            <div>
              <button
                onClick={() => handleMouseEnter('whatWeDo')}
                className="block w-full text-left text-[#A97C50] hover:text-teal-400"
              >
                What We Do
              </button>
              {hoveredMenu === 'whatWeDo' && (
                <div className="pl-4">
                  <Link to="/services" className="block py-1 text-[#A97C50] hover:text-teal-400">Services</Link>
                </div>
              )}
            </div>
            <div>
              <button
                onClick={() => handleMouseEnter('projects')}
                className="block w-full text-left text-[#A97C50] hover:text-teal-400"
              >
                Projects
              </button>
              {hoveredMenu === 'projects' && (
                <div className="pl-4">
                  <Link to="/projects" className="block py-1 text-[#A97C50] hover:text-teal-400">Projects</Link>
                </div>
              )}
            </div>
            <Link to="/contact" className="block text-[#A97C50] hover:text-teal-400">Contact</Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
