import React from 'react';
import { GrUserWorker } from 'react-icons/gr';
import { FaCheckCircle, FaLightbulb, FaUsers } from 'react-icons/fa';
import './Why Choose Us.css'; // Ensure your custom CSS filename matches

function WhyChooseUs() {
  return (
    <section className="py-8 md:py-16 px-4 md:px-6 bg-white mt-4 md:mt-0">
      <div className="container mx-auto grid grid-cols-1 gap-6 md:grid-cols-2">
        
        {/* Left Side - Text */}
        <div className="text-center md:text-left flex flex-col items-center md:items-start justify-center">
          <h2 className="text-[#35AC9F] text-3xl md:text-4xl font-semibold mb-4" style={{ fontFamily: "'Poppins', sans-serif" }}>
            Why Choose Us?
          </h2>
          <p className="text-gray-600 text-base md:text-lg mb-6" style={{ fontFamily: "'Poppins', sans-serif" }}>
            Choose Pacific Coast Solutions for unparalleled construction excellence. Our commitment to unmatched expertise, unwavering safety standards, and innovative technology sets us apart. Experience quality assurance and a client-centric approach. Build with confidence, build with Pacific Coast Solutions.
          </p>
        </div>

        {/* Right Side - Flip Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {/* Box 1 */}
          <div className="flip-card w-full h-64">
            <div className="flip-card-inner w-full h-full">
              <div className="flip-card-front bg-[#35AC9F] text-white p-4 rounded-lg shadow-lg flex flex-col items-center justify-center">
                <GrUserWorker size={40} className="mb-2" />
                <h3 className="text-lg font-semibold">Commitment to Safety</h3>
              </div>
              <div className="flip-card-back bg-[#A97C50] text-white p-4 rounded-lg shadow-lg flex items-center justify-center">
                <p className="text-sm text-center">Safety is our top priority, ensuring a secure workplace.</p>
              </div>
            </div>
          </div>

          {/* Box 2 */}
          <div className="flip-card w-full h-64">
            <div className="flip-card-inner w-full h-full">
              <div className="flip-card-front bg-[#A97C50] text-white p-4 rounded-lg shadow-lg flex flex-col items-center justify-center">
                <FaCheckCircle size={40} className="mb-2" />
                <h3 className="text-lg font-semibold">Quality Assurance</h3>
              </div>
              <div className="flip-card-back bg-[#35AC9F] text-white p-4 rounded-lg shadow-lg flex items-center justify-center">
                <p className="text-sm text-center">We guarantee high standards in every project.</p>
              </div>
            </div>
          </div>

          {/* Box 3 */}
          <div className="flip-card w-full h-64">
            <div className="flip-card-inner w-full h-full">
              <div className="flip-card-front bg-[#A97C50] text-white p-4 rounded-lg shadow-lg flex flex-col items-center justify-center">
                <FaLightbulb size={40} className="mb-2" />
                <h3 className="text-lg font-semibold">Innovative Technology</h3>
              </div>
              <div className="flip-card-back bg-[#35AC9F] text-white p-4 rounded-lg shadow-lg flex items-center justify-center">
                <p className="text-sm text-center">Our technology solutions are at the forefront of the industry.</p>
              </div>
            </div>
          </div>

          {/* Box 4 */}
          <div className="flip-card w-full h-64">
            <div className="flip-card-inner w-full h-full">
              <div className="flip-card-front bg-[#35AC9F] text-white p-4 rounded-lg shadow-lg flex flex-col items-center justify-center">
                <FaUsers size={40} className="mb-2" />
                <h3 className="text-lg font-semibold">Client-Centric Approach</h3>
              </div>
              <div className="flip-card-back bg-[#A97C50] text-white p-4 rounded-lg shadow-lg flex items-center justify-center">
                <p className="text-sm text-center">We prioritize our clients, delivering solutions that matter.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyChooseUs;
